import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <ClipLoader color={'#000000'} loading={true} size={50} />
    </div>
  );
};

export default Loader;
