/* eslint-disable @next/next/no-page-custom-font */
import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

const HtmlHeader = ({ title }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />

    </Head>
  );
};

export default HtmlHeader;

HtmlHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

HtmlHeader.defaultProps = {
  title: '',
};
